import { put, select, takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import {
  getMarkers,
  getMarkerDetails,
  getAries,
  getPolygons,
  deleteMarker,
  addLayerLink,
  runInference,
  addLayerFile
} from "services/api/map";
import { LayerAlias, MULTISPECTRAL_OPTIONS } from "constant";
import { getProject } from "services/api/project";
import { actions } from "./ducks";

function* fetchProject({ payload }) {
  const request = getProject;

  const {
    fetchProjectSuccess: setSuccess,
    fetchProjectError: setError,
    toggleLayerIsActive
  } = actions;

  function* onSuccess(res) {
    const defaultLayer = res.layers?.[0];
    if (defaultLayer) {
      const alias = defaultLayer.alias;
      const payload = { params: { alias } };

      yield put(yield toggleLayerIsActive(payload));
    }
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* startInference({ payload }) {
  const request = runInference;

  const { startInferenceSuccess: setSuccess, startInferenceError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}
// function* fetchLayers({ payload }) {
//   const request = getLayers;

//   const { fetchLayersSuccess: setSuccess, fetchLayersError: setError } =
//     actions;

//   function* onSuccess(res) {
//     const alias = res.find((item) => item.is_default).alias;
//     yield fetchMarkers({ payload: { params: { alias } } });
//   }

//   yield requestMiddleware({
//     ...payload,
//     request,
//     setSuccess,
//     setError,
//     onSuccess
//   });
// }

function* fetchAries({ payload }) {
  const request = getAries;

  const { fetchAriesSuccess: setSuccess, fetchAriesError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* handleToggleLayerIsActive({ payload }) {
  const { id, markers, layers } = yield select((state) => state.map);
  const { alias } = payload.params;

  if (alias === LayerAlias.MULTISPECTRAL) {
    const { setLayerParams } = actions;

    const layerData = layers.find((layer) => layer.alias === alias);

    const params = MULTISPECTRAL_OPTIONS(
      layerData?.rescale_min,
      layerData?.rescale_max
    )[0].params;

    yield put(yield setLayerParams({ params, alias }));
  }

  const isMarkersLoaded = markers.find((marker) => marker.layer === alias);

  if (!isMarkersLoaded)
    yield fetchMarkers({
      payload: {
        ...payload,
        params: { ...payload.params, id }
      }
    });
}

function* fetchMarkers({ payload }) {
  const request = getMarkers;

  const { fetchMarkersSuccess: setSuccess, fetchMarkersError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* fetchPolygons({ payload }) {
  const request = getPolygons;

  const { fetchPolygonsSuccess: setSuccess, fetchPolygonsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchMarkerDetails({ payload }) {
  const request = getMarkerDetails;

  const {
    fetchMarkerDetailsSuccess: setSuccess,
    fetchMarkerDetailsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createLayer({ payload }) {
  const request = payload.fields.file ? addLayerFile : addLayerLink;

  const { createLayerSuccess: setSuccess, createLayerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchDeleteMarker({ payload }) {
  const request = deleteMarker;
  const {
    fetchDeleteMarkerSuccess: setSuccess,
    fetchDeleteMarkerError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectRequest, fetchProject);
  yield takeLatest(actions.startInferenceRequest, startInference);
  yield takeLatest(actions.fetchAriesRequest, fetchAries);
  yield takeLatest(actions.fetchMarkersRequest, fetchMarkers);
  yield takeLatest(actions.createLayerRequest, createLayer);
  yield takeLatest(actions.fetchPolygonsRequest, fetchPolygons);
  yield takeLatest(actions.fetchMarkerDetailsRequest, fetchMarkerDetails);
  yield takeLatest(actions.fetchDeleteMarkerRequest, fetchDeleteMarker);
  yield takeLatest(actions.toggleLayerIsActive, handleToggleLayerIsActive);
}
