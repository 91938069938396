import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { LayerAlias } from "constant";
import features from "features";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";
import ExpandBlock from "components/ExpandBlock/ExpandBlock";
import File from "components/File/Files";
import FormField from "components/inputs/FormField/FormField";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import MapLayer from "../MapLayer/MapLayer";

import expandedLayersIcon from "assets/icons/expanded-layers.svg";
import layersIcon from "assets/icons/layers.svg";
import ReportIcon from "assets/icons/report.svg";
import styles from "./map-layers.module.scss";

const MapLayers = ({ layers, aries, setView }) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const layersRef = useRef(null);
  const layersContentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (layersContentRef.current) {
        setContentHeight(layersContentRef.current.scrollHeight);
      }
    });

    if (layersContentRef.current) {
      observer.observe(layersContentRef.current);
    }

    return () => {
      if (layersContentRef.current) {
        observer.unobserve(layersContentRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const updateBodyHeight = () => {
      setBodyHeight(document.body.clientHeight);
    };

    updateBodyHeight();

    window.addEventListener("resize", updateBodyHeight);

    return () => {
      window.removeEventListener("resize", updateBodyHeight);
    };
  }, []);

  const handleToggleOpenState = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleFormSubmit = useCallback(
    (alias) => (values) => {
      dispatch(
        features.map.actions.createLayerRequest({
          params: { id, alias },
          fields: values,
          onSuccess: (res) => {
            dispatch(
              features.modal.actions.hideModal({
                modalType: "FORM"
              })
            );
            dispatch(
              features.map.actions.fetchProjectRequest({
                params: { id }
              })
            );
            const center = res.center;
            if (center) setView([res.center[1], res.center[0]], res.center[2]);

            dispatch(
              features.modal.actions.showModal({
                modalType: "SUCCESS",
                modalProps: {
                  title: `Add ${alias}`,
                  acceptButtonAction: () =>
                    dispatch(
                      features.modal.actions.hideModal({
                        modalType: "SUCCESS"
                      })
                    )
                }
              })
            );
          },
          onError: (res) => {
            console.log(res);
          }
        })
      );
    },
    [dispatch, id, setView]
  );

  const addLayerAction = useCallback(
    (alias) => () => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: `Add ${alias}`,
            description:
              alias === LayerAlias.THERMAL
                ? "Please select a file."
                : "Please make sure to use the task link from ODM, not the project link.",
            warningDescription: true,
            initialValues: {
              ...(alias === LayerAlias.THERMAL
                ? { file: undefined }
                : { link: "" })
            },
            onSubmit: handleFormSubmit(alias),
            validationSchema: Yup.object().shape({
              ...(alias === LayerAlias.THERMAL
                ? { file: Yup.mixed().required("File is required") }
                : { link: Yup.string().required("Link is required") })
            }),
            acceptButtonLabel: "Save",
            declineButtonAction: () =>
              dispatch(
                features.modal.actions.hideModal({
                  modalType: "FORM"
                })
              ),
            formContentWithContext: (setValue, values) => (
              <>
                {alias === LayerAlias.THERMAL ? (
                  <>
                    <File fileName={values.file?.name ?? "Name zip file"} />
                    <UploadFiles
                      title="Upload zip"
                      handleFileChange={(event: any) => {
                        setValue("file", event.target.files[0]);
                      }}
                    />
                  </>
                ) : (
                  <FormField
                    name="link"
                    placeholder="https://example.com/feed.xml"
                  />
                )}
              </>
            )
          }
        })
      );
    },
    [dispatch, handleFormSubmit]
  );

  const startInference = useCallback(
    (alias: LayerAlias) => () => {
      dispatch(
        features.map.actions.startInferenceRequest({
          params: { id, alias },
          onSuccess: () => {
            dispatch(
              features.map.actions.toggleLayerIsActive({
                params: { alias, isActive: true }
              })
            );
          }
        })
      );
    },
    [dispatch, id]
  );

  const goToReport = useCallback(() => {
    navigate("report");
  }, [navigate]);

  return (
    <div
      className={classNames(styles["layers"], {
        [styles["layers--active"]]: isOpen
      })}
      ref={layersRef}
    >
      <div
        className={classNames(styles["layers__button"], {
          [styles["layers__button-active"]]: isOpen
        })}
      >
        <div
          className={classNames(styles["layers__button-small-container"], {
            [styles["layers__button-small-container-active"]]: isOpen
          })}
          onClick={(e) => {
            e.stopPropagation();
            handleToggleOpenState();
          }}
        >
          <span className={styles["layers__button-label"]}>Layers</span>
          <div
            className={classNames(
              styles["icon-container"],
              styles["layers-icon"],
              {
                [styles["layers-icon__inactive"]]: isOpen
              }
            )}
          >
            <img src={layersIcon} alt="layers-button" />
          </div>
          <div
            className={classNames(
              styles["icon-container"],
              styles["layers-expanded-icon"],
              {
                [styles["layers-expanded-icon__active"]]: isOpen
              }
            )}
          >
            <img src={expandedLayersIcon} alt="layers-button" />
          </div>
        </div>
        <div
          className={classNames(
            styles["layers-wrapper"],
            {
              [styles["layers-wrapper__active"]]: isOpen
            },
            {
              [styles["layers-wrapper__big-window"]]:
                contentHeight > bodyHeight - 40
            }
          )}
          ref={layersContentRef}
        >
          <div className={styles["layers-content"]}>
            <div className={styles["layers__list"]}>
              {layers.map((layer, index) => (
                <MapLayer
                  {...layer}
                  key={`layer-${index}`}
                  startInference={startInference(layer.alias)}
                  addLayerAction={addLayerAction(layer.alias)}
                />
              ))}
            </div>
            {layers.length > 0 && (
              <ExpandBlock title="Areas">
                <div className={styles["layers__list-areas"]}>
                  {aries.map((layer, index) => (
                    <MapLayer
                      {...layer}
                      key={`layer-${index}`}
                      isPolygon
                      isDisabledView={isEmpty(layer)}
                    />
                  ))}
                </div>
              </ExpandBlock>
            )}
            <ExpandBlock title="Other">
              <ShowHideButton
                title={"Photos"}
                onClick={() => {}}
                isActive={false}
                isDisabled={true}
              />
            </ExpandBlock>
            <ExpandBlock title="Action">
              <CommonButton
                type="button"
                icon={ReportIcon}
                onClick={goToReport}
              >
                Create report
              </CommonButton>
            </ExpandBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapLayers;
