import classNames from "classnames";

import { useEffect, useState } from "react";

import BurgerButton from "components/buttons/Burger/Burger";

import ArrowIcon from "../../assets/icons/arrow-left.svg";
import logo from "../../assets/logotype.svg";

import styles from "./header.module.scss";

interface HeaderInterface {
  title?: string;
  isFullFill?: boolean;
  backPath?: string;
}

const Header = ({ isFullFill, backPath }: HeaderInterface) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 20) {
        setIsScrolled(true);
      } else setIsScrolled(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={styles["header"]}>
      <div
        className={classNames(styles["header__wrapper"], {
          [styles["header__ful-fill-wrapper"]]: isFullFill,
          [styles["header__wrapper--scrolled"]]: isScrolled
        })}
      >
        <BurgerButton />
        {backPath && (
          <a className={styles["header__logo"]} href={backPath}>
            <img src={ArrowIcon} alt="Dropla Logo"></img>
          </a>
        )}
        <a className={styles["header__logo"]} href="/">
          <img src={logo} alt="Dropla Logo"></img>
        </a>
      </div>
    </header>
  );
};

export default Header;
