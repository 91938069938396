import { env } from "./env";

import { MainUtilInterface } from "components/MainUtil/MainUtil";

import AiHelperPicture from "assets/ai-helper-picture.png";
import LabelingPicture from "assets/labeling-picture.png";
import OdmPicture from "assets/odm-picture.png";
import WikiPicture from "assets/wiki-picture.png";
import MineDetectionPicture from "assets/mine-detection-picture.png";

export const AUTH_URL = env.REACT_APP_AUTH_URL;
export const GEO_VISION_API_URL = env.REACT_APP_GEO_VISION_API_URL;
export const ODM_API_URL = env.REACT_APP_OVD_API_URL;
export const EXTERNAL_API_URL = env.REACT_APP_EXTERNAL_API_URL;
export const PRIVATE_KEY = env.REACT_APP_PRIVATE_KEY;

//DELETE
export const AREA_COLORS = [
  {
    name: "Medium Hazard",
    color: "rgba(255, 122, 0, 0.8)",
    alias: "orange",
    isActive: false,
    radius: 3
  },
  {
    name: "Hard Hazard",
    color: "rgba(255, 0, 0, 0.8)",
    alias: "red",
    isActive: false,
    radius: 3
  }
];

const ODM_LINK = "http://10.10.90.250:8000";
const CVAT_LINK = "http://10.10.90.250:8080";
const AI_HELPER_LINK = "https://ai-helper.vision.dropla.tech/helper/admin";
const WIKI_LINK = "https://wiki-admin.vision.dropla.tech";

export const UTILS_LIST: Array<MainUtilInterface> = [
  { title: "ODM", icon: OdmPicture, link: ODM_LINK, target: "_blank" },
  {
    title: "Labeling tool",
    icon: LabelingPicture,
    link: CVAT_LINK,
    target: "_blank"
  },
  {
    title: "AI Helper",
    icon: AiHelperPicture,
    link: AI_HELPER_LINK,
    target: "_blank"
  },
  {
    title: "Wiki mine",
    icon: WikiPicture,
    link: WIKI_LINK,
    target: "_blank"
  },
  //TODO delete orthophoto-planning scenes
  {
    title: "Mine detection",
    icon: MineDetectionPicture,
    link: "/image-detection"
  }
  // { title: "Map", icon: MapIcon, link: "/map" },
  // {
  //   title: "Orthophoto Planning",
  //   icon: CameraIcon,
  //   link: "/orthophoto-planning"
  // }
];

export const KYIV_COORDINATES = { lat: 50.450001, lng: 30.523333 };

export enum LayerAlias {
  RGB = "rgb",
  MULTISPECTRAL = "multispectral",
  THERMAL = "thermal"
  //TODO for  sensorFusion
  // ALL = "All"
}

export interface LayerInterface {
  name: string;
  color: string;
  alias: LayerAlias;
  isInit?: boolean;
}

export const LAYERS: Record<LayerAlias, LayerInterface> = {
  [LayerAlias.RGB]: {
    name: "RGB",
    color: "#FF0000",
    alias: LayerAlias.RGB
  },
  [LayerAlias.MULTISPECTRAL]: {
    name: "Multispectral",
    color: "#00CB50",
    alias: LayerAlias.MULTISPECTRAL
  },
  [LayerAlias.THERMAL]: {
    name: "Thermal",
    color: "#00D1FF",
    alias: LayerAlias.THERMAL
  }
  // [LayerAlias.ALL]: {
  //   name: "Sensor fusion",
  //   color: "#00D1FF",
  //   alias: LayerAlias.ALL
  // }
};

export const DEFAULT_LAYERS_LIST: LayerInterface[] = Object.values(LAYERS);

export const MULTISPECTRAL_OPTIONS = (min, max) => {
  //TODO remove minRescale and maxRescale. Default values for old projects
  const minRescale = min === 0 ? -0.0009298980003222823 : min;
  const maxRescale = max === 0 ? 0.05864158272743225 : max;
  return [
    {
      name: "RGB",
      params: `?rescale=${minRescale}%2C${maxRescale}`
    },
    {
      name: "NDVI",
      params:
        "?color_map=viridis&formula=NDVI&bands=auto&hillshade=&rescale=-0.551,0.26"
    },
    {
      name: "vNDVI",
      params:
        "?color_map=spectral_r&formula=vNDVI&bands=auto&hillshade=&rescale=0.56,0.79"
    },
    {
      name: "EXG",
      params:
        "?color_map=cividis&formula=EXG&bands=auto&hillshade=&rescale=-0.009427607990801334,0.019022520631551743"
    }
  ];
};
